import cx from 'classnames'
import dynamic from 'next/dynamic'

import { type SanityHeroModule } from '@data/sanity/queries/types/modules'
import { getVimeoVideoId } from '@lib/video'

import Photo from '@components/photo'
import ComplexPortableText from '@components/complex-portable-text'

const LottieAnimation = dynamic(() => import('@components/lottie-animation'))
const MuxVideo = dynamic(() => import('@components/video/mux-video'))
const VimeoVideoLoop = dynamic(
  () => import('@components/video/vimeo-video-loop'),
)

type HeroProps = Pick<
  SanityHeroModule,
  | 'content'
  | 'contentPosition'
  | 'bgType'
  | 'photos'
  | 'lottieAssetUrl'
  | 'vimeoVideo'
  | 'muxVideo'
> & {
  overlay?: boolean
}

const getRatio = (ratio?: number, defaultRatio?: number) => {
  if (typeof ratio === 'undefined' || ratio === 0) {
    return defaultRatio ?? 1
  }

  return ratio
}

const Hero = ({
  content,
  contentPosition,
  bgType,
  photos,
  lottieAssetUrl,
  vimeoVideo,
  muxVideo,
  overlay,
}: HeroProps) => {
  const vimeoVideoId = getVimeoVideoId(vimeoVideo?.link)

  return (
    <section
      className={cx(
        'relative overflow-hidden bg-pageBG max-h-screen min-h-[500px] xs:min-h-[auto]',
        {
          'before:absolute before:inset-0 before:z-[1] before:bg-pageText before:bg-opacity-50':
            overlay,
        },
      )}
    >
      <div
        className={cx('container absolute inset-0 z-10 flex flex-col', {
          'justify-end': contentPosition === 'bottom-left',
          'justify-center items-center': contentPosition === 'center',
        })}
      >
        {!!content && (
          <div
            className={cx('z-10 w-full text-pageBG mt-14', {
              'md:max-w-[70%] xl:max-w-[50%] mb-4 md:mb-10':
                contentPosition === 'bottom-left',
              'lg:max-w-[55%]': contentPosition === 'center',
            })}
          >
            <div
              className={cx('rc rc-hero', {
                'text-center': contentPosition === 'center',
              })}
            >
              <ComplexPortableText content={content} />
            </div>
          </div>
        )}
      </div>

      <div>
        {bgType === 'photo' && !!photos && (
          <>
            {photos.desktopPhoto && (
              <Photo
                image={photos.desktopPhoto}
                fill
                priority
                className="relative w-full z-0 h-full hidden sm:block"
                style={{
                  paddingTop: `${
                    100 / getRatio(photos.desktopPhoto.customRatio, 16 / 9)
                  }%`,
                }}
                imageClassName="object-cover"
              />
            )}
            {photos.mobilePhoto && (
              <Photo
                image={photos.mobilePhoto}
                fill
                priority
                className="relative w-full z-0 h-full sm:hidden"
                style={{
                  paddingTop: `${
                    100 / getRatio(photos.mobilePhoto.customRatio, 5 / 7)
                  }%`,
                }}
                imageClassName="object-cover"
              />
            )}
          </>
        )}

        {bgType === 'lottie-animation' && !!lottieAssetUrl && (
          <LottieAnimation assetUrl={lottieAssetUrl} />
        )}

        {bgType === 'vimeo-video' && !!vimeoVideo && !!vimeoVideoId && (
          <VimeoVideoLoop title={vimeoVideo.name} id={vimeoVideoId} />
        )}

        {bgType === 'mux-video' && !!muxVideo && (
          <MuxVideo
            video={muxVideo}
            showControls={false}
            autoplay
            muted
            loop
            customAspectRatio={{
              custom: true,
              desktop: '16:9',
              mobile: '5:7',
            }}
          />
        )}
      </div>
    </section>
  )
}

export default Hero
